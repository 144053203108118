import React from "react";
import { SocialSection } from "./social";

export function HeroSection() {
  return (
    <>
      <section className="hero is-halfheight">
        <div className="hero-body">
          <div className="container content">
            <div className="columns is-vcentered has-text-centered">
              <div className="column is-three-fifths is-offset-one-fifth">
                <h1 className="title is-1">Setlist Live.</h1>

                <div className="has-text-justified">
                  <p>
                    Setlist Live est une application permettant à votre groupe
                    de musique de partager votre catalogue musical, et d'en
                    construire des setlists à parcourir <strong>EN LIVE</strong>{" "}
                    pendant les concerts.
                  </p>
                  <p>
                    Si vous êtes chanteur, le morceau affichera les paroles,
                    sinon, vous pouvez lire votre tablature préférée ! Dès que
                    le morceau change, tout le monde change !
                  </p>
                </div>

                <SocialSection />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
