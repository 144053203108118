import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export function DemoSection() {
  return (
    <section className="section section-gray">
      <div className="container content is-hidden-tablet">
        <h2>
          Les <strong>fonctionnalités</strong>
        </h2>
      </div>

      <div className="columns is-vcentered is-mobile carousel">
        <div className="column">
          <StaticImage
            src="../../images/screen-1.png"
            alt="Joue tes morceaux"
            placeholder="blurred"
            layout="constrained"
            width={400}
            imgStyle={{ borderRadius: "10px" }}
          />
        </div>
        <div className="column is-4-desktop">
          <StaticImage
            src="../../images/ipad-screen-2.png"
            alt="Joue synchronisé entre appareils"
            placeholder="blurred"
            layout="constrained"
            width={700}
            imgStyle={{ borderRadius: "10px" }}
          />
        </div>
        <div className="column">
          <StaticImage
            src="../../images/screen-3.png"
            alt="Gère tes setlists"
            placeholder="blurred"
            layout="constrained"
            width={400}
            imgStyle={{ borderRadius: "10px" }}
          />
        </div>
      </div>
    </section>
  );
}
